import { useCoingeckoPrice } from '@usedapp/coingecko'

function usePrices () {   
    const ETH = parseFloat(useCoingeckoPrice('ethereum', 'usd'))
    const BNB = parseFloat(useCoingeckoPrice('binancecoin', 'usd'))
    const MATIC = parseFloat(useCoingeckoPrice('matic-network', 'usd'))

    return { ETH, BNB, MATIC }
}

export default usePrices
