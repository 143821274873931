import { useTranslation } from 'react-i18next'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'

import Currencies from '../../_currencies'

function NetworkSelector ({ isOpen=false, setIsOpen }) {
    const { t } = useTranslation()

    const changeNetwork = async chainId => {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${chainId.toString(16)}` }]
        })
    }

    return (
        <Dialog onClose={() => setIsOpen(false)} open={isOpen}>
            <DialogTitle>{t('Choose Network')}</DialogTitle>
            <List sx={{ padding: '1.5rem' }}>
                {
                    Object.keys(Currencies).map(key => {
                        const currency = Currencies[key]

                        return (
                            <ListItem button key={key}>
                                <ListItemText primary={currency.name} onClick={() => changeNetwork(currency.id)} />
                            </ListItem>
                        )
                    })
                }
            </List>
        </Dialog>
    )
}

export default NetworkSelector