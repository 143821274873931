import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shortenAddress, useEtherBalance, useEthers } from '@usedapp/core'
import { formatEther } from '@ethersproject/units'
import { Link, useLocation } from 'react-router-dom'

import Button from '@mui/material/Button'

import State from '../state'
import usePrices from '../hooks/usePrices'
import NetworkSelector from './dialogs/NetworkSelector'

function WalletInfo () {
    const { t } = useTranslation()
    const location = useLocation()
    const { state } = useContext(State)
    const [networkSelectorOpen, setNetworkSelectorOpen] = useState()
    const { activateBrowserWallet, account, deactivate } = useEthers()
    const etherBalance = useEtherBalance(account)
    const prices = usePrices()

    return (
        <>
            {
                account &&
                    <span style={{ marginRight: '1rem', fontSize: '0.75rem', textAlign: 'right'}}>
                        <strong>{parseFloat(formatEther(etherBalance || 0)).toFixed(4)} {state.currency?.coin}</strong>
                        <br />
                        {
                            etherBalance && prices[state.currency?.coin] &&
                                <small>
                                    (${(parseFloat(formatEther(etherBalance || 0)) * (prices[state.currency?.coin] || 0)).toFixed(2)})
                                </small>
                        }
                    </span>
            }

            {
                account &&
                    <Button 
                        color='secondary' 
                        variant='outlined' 
                        style={{ marginRight: '1rem' }}
                        title={t('Choose Network')}
                        onClick={() => setNetworkSelectorOpen(true)}
                    >
                        {state.currency?.name}
                    </Button>
            }

            {
                location.pathname !== '/cs/exploit' &&
                    <Button 
                        color='success' 
                        variant='outlined' 
                        style={{ marginRight: '1rem' }}
                    >
                        <Link to='/exploit'>{t('Exploit')}</Link>
                    </Button>
            }

            {!account && <Button variant='contained' onClick={activateBrowserWallet}>{t('Connect')}</Button>}
            {account && <Button title={t('Disconnect')} onClick={deactivate} variant='outlined' style={{ textTransform: 'none', marginRight: '1rem' }}>{shortenAddress(account)}</Button>}

            <NetworkSelector isOpen={networkSelectorOpen} setIsOpen={setNetworkSelectorOpen} />
        </>
    )
}

export default WalletInfo
