import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'

import logo from '../assets/logo192.png'
import WalletInfo from './WalletInfo'

function TopNav ({ currency }) {
    const { t } = useTranslation()
    const isMobile = useMediaQuery('(max-width: 740px)')

    useEffect(() => console.log({ isMobile }), [isMobile])

    const TopBar = styled(AppBar)({
        height: 64
    })

    const SiteTitle = styled(Link)({
        fontSize: '2.5rem',
        fontWeight: 800,
        fontVariant: 'small-caps',
        flexGrow: 1
    })

    const SiteLogo = styled('img')({
        maxHeight: 48,
        marginRight: '1.5rem'
    })

    return (
        <>
            <TopBar position='static'>
                <Toolbar>
                    <SiteLogo src={logo} alt='' />
                    <SiteTitle to='/'>{t('Coinsploit')}</SiteTitle>
                    {!isMobile && <WalletInfo />}
                </Toolbar>
            </TopBar>
        </>
    )
}

export default TopNav
