import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

function ErrorDialog ({ error=false, setError }) {
    const { t } = useTranslation()

    const close = () => setError(false)

    return (
        <Dialog onClose={close} open={Boolean(error)}>
            <DialogTitle>{t('Error')}</DialogTitle>
            <DialogContent>
                {error}
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>{t('OK')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ErrorDialog