import { useContext, useEffect } from 'react'
import { useEthers } from '@usedapp/core'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'

import State from './state'
import Currencies from './_currencies'
import TopNav from './components/TopNav'
import WalletInfo from './components/WalletInfo'
import Home from './components/Home'
import Exploit from './components/Exploit'

function Interface () {
  const { t } = useTranslation()
  const { chainId } = useEthers()
  const { state, setState } = useContext(State)
  const isMobile = useMediaQuery('(max-width: 740px)')

  useEffect(() => {
    if (!chainId) return
    const currency = Object.values(Currencies).find(c => c.id === chainId)
    setState(s => ({ ...s, currency }))
  }, [chainId, setState])

  return (
      <>
        <Helmet>
          <title>{`${t('Coinsploit')} : ${t('Double Your')} ${state.currency?.name || t('Crypto')} (${state.currency?.coin || 'ETH/BNB/MATIC'})`}</title>
        </Helmet>

        <CssBaseline />

        <Router basename='/cs'>
          <TopNav />

          {
            isMobile && 
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                <WalletInfo />
              </Box>
          }

          <Box sx={{ marginTop: '1rem', display: 'flex', justifyContent: 'center' }}>
            <Switch>
              <Route path ='/exploit'>
                <Exploit />
              </Route>
              <Route path='/' exact>
                <Home />
              </Route>
            </Switch>
          </Box>

          <Box sx={{ marginTop: '3rem', textAlign: 'center' }}>
            <Typography gutterBottom variant='h1' sx={{ fontSize: '2rem', fontWeight: 'bold' }}>Verified by:</Typography>

            <a href="http://zqktlwigz2pqrru3i6aed4x4rm7j42dbyxcpgh63qiyavuweks3ajzid.onion" rel="noopener noreferrer" target="_blank">
              <img alt='The Hidden Wiki' src='wiki.png' style={{ backgroundColor: '#eee', borderRadius: '25px' }} />
            </a>

            <div style={{ marginTop: '2rem' }}></div>

            <a href="http://pqqmr3p3tppwqvvapi6fa7jowrehgd36ct6lzr26qqormaqvh6gt4jyd.onion" rel="noopener noreferrer" target="_blank">
              <img alt='Versus Market' src='versus.png' style={{ marginRight: '4rem' }} />
            </a>

            <a href="http://xmh57nhjcrktcsmbiu3efjwqajbtyu33rpqxcmovnplgck5e4dutdpad.onion" rel="noopener noreferrer" target="_blank">
              <img alt='TORCH' src='torch.png' style={{ filter: 'invert(100%)' }} />
            </a>
          </Box>
        </Router>
      </>
  )
}

export default Interface
