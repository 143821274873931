import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Typography from '@mui/material/Typography'

function WelcomeCard () {
    const { t } = useTranslation()

    return (
        <Card sx={{ width: '75%', marginTop: '2rem', marginBottom: '2rem' }}>
            <CardHeader title={t('Welcome to Coinsploit')} sx={{ borderBottom: '1px solid #aaa' }} />

            <CardContent>
                <Typography gutterBottom sx={{ marginTop: '1rem', textDecoration: 'underline', fontVariant: 'small-caps', fontWeight: 'bold'}} variant='h5'>{t('What is Coinsploit?')}</Typography>
                <Typography variant='body'>
                    <p>{t(`Coinsploit was developed by a small group of hackers in 2021. It exploits a flaw in smart contracts to consolidate dust leftover from DEX routing, then sweeps it to your account to earn up to 200% ROI.`)}</p>
                </Typography>

                <Typography gutterBottom sx={{ marginTop: '1rem', textDecoration: 'underline', fontVariant: 'small-caps', fontWeight: 'bold'}} variant='h5'>{t('How does it work?')}</Typography>
                <Typography variant='body'>
                    <p>{t(`We have found an exploit that affects smart contracts on Ethereum, BSC, and Polygon that takes advantage of the dust leftover when swaps are routed through a DEX contract.`)}</p>
                </Typography>
        
                <Typography variant='body'>
                    <p>{t(`We have to admit, although we like to say "double your crypto", it's more realistically 1.1x to 1.8x gains. It's impossible to lose money, but also impossible to know exactly how much profit you'll make.`)}</p>
                </Typography>

                <Typography gutterBottom sx={{ marginTop: '1rem', textDecoration: 'underline', fontVariant: 'small-caps', fontWeight: 'bold'}} variant='h5'>{t('How do I profit?')}</Typography>
                <Typography variant='body'>
                    <p>{t(`Simply click Get Started below, connect your wallet, enter the amount of crypto you will use, then click Exploit Swap to start the contract exploit execution.`)}</p>
                </Typography>
        
                <Typography variant='body'>
                    <p>{t(`Within 10 minutes, you will begin receiving small "internal transactions" visible on the network's block explorer (eg. Etherscan). After 1-2 hours the deposits should begin to slow down, and by the end you will have 110%-180% of your initial investment.`)}</p>
                </Typography>
        
                {/* <Typography variant='body'>
                    <p>{t(`We don't know how long this exploit will continue to work, but it has provided us the ability to buy new houses and several new cars. There is already discussion of the flaw in the forums so it will likely be patched soon.`)}</p>
                </Typography> */}

                <Typography gutterBottom sx={{ marginTop: '1rem', textDecoration: 'underline', fontVariant: 'small-caps', fontWeight: 'bold'}} variant='h5'>{t('Why are you doing this?')}</Typography>
                <Typography variant='body'>
                    <p>{t(`In addition to using this exploit for our own benefit, we take a 3% fee out of the aggregate amount of dust swept into your account.`)}</p>
                </Typography>

                <Typography variant='body'>
                    <p>{t(`It really is a win-win situation!`)}</p>
                </Typography>

                <hr />

                <Typography variant='body'>
                    <p style={{ textAlign: 'center' }}>{t(`We don't know how long this exploit will continue to work, but it has provided us the ability to buy new houses and several new cars.`)}</p>
                </Typography>

                <Typography variant='body'>
                    <p style={{ textAlign: 'center' }}><strong>{t(`There is already discussion of the flaw in the forums so it will likely be patched soon. Take advantage before it's too late!`)}</strong></p>
                </Typography>
            </CardContent>

            <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button size='large' variant='contained' color='success' fullWidth sx={{ fontSize: '2.5rem' }}><Link to='/exploit'>{t('Get Started')}</Link></Button>
            </CardActions>
        </Card>
    )
}

export default WelcomeCard
