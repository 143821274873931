import WelcomeCard from './cards/WelcomeCard'

function Home () {
    return (
        <>
            <WelcomeCard />
        </>
    )
}

export default Home
