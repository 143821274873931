import { useState } from 'react'
import { ChainId, DAppProvider } from '@usedapp/core'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import './i18n'
import './App.css'
import State from './state'
import Interface from './Interface'

const theme = createTheme({ palette: { mode: 'dark' } })

function App () {
  const [state, setState] = useState({
    dappConfig: {
      readOnlyChainId: ChainId.Mainnet,
      readOnlyUrls: {
        [ChainId.Mainnet]: 'https://mainnet.infura.io/v3/66d5c53e08e54cb396cc42d1a0d616f5'
      }
    }
  })

  return (
    <>
      <DAppProvider config={state.dappConfig}>
        <ThemeProvider theme={theme}>
          <State.Provider value={{ state, setState }}>
            <Interface />
          </State.Provider>
        </ThemeProvider>
      </DAppProvider>
    </>
  )
}

export default App
