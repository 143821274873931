import { ChainId } from '@usedapp/core'

const currencies =  {
    ETH: {
        id: ChainId.Mainnet,
        name: 'Ethereum',
        coin: 'ETH',
        shortName: 'ETH',
        coingeckoId: 'ethereum'
    },

    BSC: {
        id: ChainId.BSC,
        name: 'Binance Smart Chain',
        coin: 'BNB',
        shortName: 'BSC',
        coingeckoId: 'binancecoin'
    },

    MATIC: {
        id: ChainId.Polygon,
        name: 'Polygon (MATIC)',
        coin: 'MATIC',
        shortName: 'MATIC',
        coingeckoId: 'matic-network'
    }
}

export default currencies
